import axios from "axios";
import { Loading, Message } from "element-ui";
import _ from "lodash";

// loading对象
let loading;

// 当前正在请求的数量
let needLoadingRequestCount = 0;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 显示loading
function showLoading(target) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Loading.service({
      lock: true,
      text: "加载中...",
      background: "rgba(255, 255, 255, 0.5)",
      target: target || "body",
    });
  }
  needLoadingRequestCount++;
}

// 隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0);
  if (needLoadingRequestCount === 0) {
    // 关闭loading
    toHideLoading();
  }
}

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
// eslint-disable-next-line no-var
var toHideLoading = _.debounce(() => {
  if (loading) {
    loading.close();
    loading = null;
  }
}, 300);
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  headers: { 'content-type': 'application/json;charset=utf-8' },
  timeout: 30000, // 请求超时时间
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["token"] = window.localStorage.token;
    // do something before request is sent
    // 判断当前请求是否设置了不显示Loading

    if (config.headers.showLoading !== false) {
      showLoading(config.headers.loadingTarget);
    }
    return config;
  },
  (error) => {
    // Do something with request error
    hideLoading();
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    if (response.config.headers.showLoading !== false) {
      hideLoading();
    }
    const res = response.data;
    if (res.code == 200) {
      return res;
    } else {
      // if (res.code == 670) {
      //   window.localStorage.token = ''
      //   Message({
      //     message: '登录失效，请重新登录',
      //     type: "error",
      //     duration: 2 * 1000,
      //   });
      //   location.href = '/login';
      //   return Promise.reject('登录失效，请重新登录');
      // }
      Message({
        message: res.msg,
        type: "error",
        duration: 2 * 1000,
      });
      return Promise.reject(res);
    }
  },
  (error) => {
    hideLoading();
    let message = "";
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          message = "请求错误(400)";
          break;
        case 401:
          message = error.message || "没有权限(401)";
          break;
        case 403:
          message = "拒绝访问(403)";
          break;
        case 404:
          message = "请求出错(404)";
          break;
        case 408:
          message = "请求超时(408)";
          break;
        case 500:
          message = "服务器错误(500)";
          break;
        case 501:
          message = "服务未实现(501)";
          break;
        case 502:
          message = "网络错误(502)";
          break;
        case 503:
          message = "服务不可用(503)";
          break;
        case 504:
          message = "网络超时(504)";
          break;
        case 505:
          message = "HTTP版本不受支持(505)";
          break;
        default:
          message = `连接出错(${error.response.status})!`;
      }
    } else {
      message = "连接服务器失败!";
    }
    Message({
      message: message,
      type: "error",
      duration: 2 * 1000,
    });
    return Promise.reject(error);
  }
);
export default service
